import React, { memo, useEffect, useMemo, useState } from 'react';
import { RouterLink } from '$shared/components';
import { M11HeroModule } from '~/lib/data-contract';
import { useCookieConsent } from '~/shared/hooks/useCookieConsent';
import { useModule } from '~/templates';
import { ModuleContainer } from '../ModuleContainer';
import { SpotTextBlock } from '../TextBlock';
import { queriesIsolated, useThemeShade } from '~/theme';
import {
    StyledM11,
    StyledM11Content,
    StyledMediaWrapper,
    StyledM11AlignContent,
    StyledM11CoverLink,
} from './styled';
import { itemMapper } from '~/templates/blocks/utils/itemMapper';
import { useTheme } from '@emotion/react';
import { usePage } from '$templates/pages';
import { M11HeroImage } from '$templates/blocks/components/M11Hero/M11HeroImage';
import { M11HeroVideo } from '$templates/blocks/components/M11Hero/M11HeroVideo';
import { M11HeroListItems } from '$templates/blocks/components/M11Hero/M11HeroListItems';

export type M11HeroProps = M11HeroModule;

export const M11Hero = memo(
    ({
        headline,
        subHeadline,
        subHeadlineUppercase,
        text,
        height: heightTemp,
        horizontalAlignment = 'left',
        verticalAlignment = 'center',
        spacingTop,
        categories,
        backgroundColor: initialBackgroundColor,
        hasCategoriesMediaFilter,
        hideMobile,
        hideDesktop,
        ...rest
    }: M11HeroProps) => {
        const pagesPossibleToHideModule = [
            'p20FrontPage',
            'p40productListPage',
            'p41productListPage',
            'p60ModulePage',
        ];
        const { type } = usePage();
        const height = heightTemp || 'normal';
        const { index } = useModule();
        const { colors } = useTheme();
        const moduleTheme = useThemeShade({
            backgroundColor: initialBackgroundColor,
        });
        const { backgroundColor, skeletonShade } = moduleTheme || {};
        const { marketing, statistic } = useCookieConsent();

        const firstItem = useMemo(
            () => (categories && categories.length ? categories[0] : undefined),
            [categories]
        );
        const listItems = useMemo(() => itemMapper(categories)?.slice(1) || [], [categories]);

        const [mediaType, setMediaType] = useState<'video' | 'image'>(
            firstItem?.video?.src && marketing && statistic ? 'video' : 'image'
        );

        // Force higher quality on mobile
        const sizes = `
            ${queriesIsolated.xs} 200vw, ${queriesIsolated.sm} 200vw, ${queriesIsolated.lg} ${
            listItems.length ? '60vw' : '80vw'
        }, 100vw`;

        const hasMediaGradient = firstItem?.hasMediaFilter && mediaType === 'image';
        const hasTextContent = Boolean(headline || subHeadline || text || firstItem?.callToAction);

        useEffect(() => {
            setMediaType(firstItem?.video?.src && marketing && statistic ? 'video' : 'image');
        }, [marketing, statistic, firstItem?.video, setMediaType]);

        return (
            <ModuleContainer
                {...rest}
                spacingTop={index === 0 ? 'none' : spacingTop}
                hideMobile={hideMobile && type && pagesPossibleToHideModule.includes(type)}
                hideDesktop={hideDesktop && type && pagesPossibleToHideModule.includes(type)}
            >
                <StyledM11
                    height={height}
                    backgroundColor={backgroundColor}
                    mediaAlignment={firstItem?.mediaHorizontalAlignment || 'left'}
                    isTwoColumn={Boolean(listItems.length)}
                >
                    {firstItem && (
                        <StyledM11Content
                            contentAlignment={horizontalAlignment}
                            height={height}
                            gradient={hasMediaGradient}
                        >
                            <StyledMediaWrapper>
                                {mediaType === 'image' && (
                                    <M11HeroImage
                                        {...{
                                            mediaType,
                                            image: firstItem.image,
                                            index,
                                            skeletonShade,
                                            sizes,
                                            height,
                                        }}
                                    />
                                )}
                                {mediaType === 'video' && (
                                    <M11HeroVideo
                                        {...{
                                            video: firstItem.video,
                                            hasTextContent,
                                            imageSrc: firstItem.image?.src,
                                            callToAction: firstItem.callToAction,
                                            posterSizes: sizes,
                                        }}
                                    />
                                )}
                            </StyledMediaWrapper>

                            <StyledM11AlignContent
                                alignment={horizontalAlignment}
                                position={verticalAlignment}
                            >
                                <SpotTextBlock
                                    headline={headline || firstItem.title}
                                    headlineVariant={index === 0 ? 'display1' : 'display2'}
                                    subheadline={subHeadline}
                                    subHeadlineUppercase={subHeadlineUppercase}
                                    text={text || firstItem.text}
                                    callToAction={firstItem.callToAction}
                                    backgroundColor={colors.dark}
                                    useTranslationForLinkTitle
                                />
                            </StyledM11AlignContent>

                            {mediaType === 'image' &&
                                firstItem.image?.src &&
                                firstItem.callToAction?.url && (
                                    <RouterLink href={firstItem.callToAction.url}>
                                        <StyledM11CoverLink tabIndex={-1} aria-hidden="true" />
                                    </RouterLink>
                                )}
                        </StyledM11Content>
                    )}
                    <M11HeroListItems
                        {...{ listItems, backgroundColor, hasCategoriesMediaFilter, height }}
                    />
                </StyledM11>
            </ModuleContainer>
        );
    }
);

export default M11Hero;
