import React from 'react';
import { MediaAndTextItem } from '~/lib/data-contract';
import { ImageAspectRatio } from '$templates/blocks/components/Category/styled';
import {
    StyledCategory,
    StyledM11CategoryContent,
    StyledM11CategoryList,
} from '$templates/blocks/components/M11Hero/styled';
import { weakKey } from '$shared/utils/jsx';

type M11ListItemsPropsType = {
    listItems?: MediaAndTextItem[];
    backgroundColor?: string;
    hasCategoriesMediaFilter?: boolean;
    height?: 'compact' | 'narrow' | 'normal' | 'tall';
};
export const M11HeroListItems = ({
    listItems,
    backgroundColor,
    hasCategoriesMediaFilter,
    height,
}: M11ListItemsPropsType) => {
    const amountOfCategories = listItems?.length || 0;
    const imagesVW = amountOfCategories === 1 && height === 'tall' ? 60 : 30;

    const categoryAspectRatio: ImageAspectRatio =
        amountOfCategories <= 2
            ? {
                  mobile: [10, 9],
                  desktop: [1, 1],
              }
            : {
                  mobile: [4, 3],
                  desktop: [3, 1],
              };

    return (
        <>
            {listItems?.length ? (
                <StyledM11CategoryContent>
                    <StyledM11CategoryList>
                        {listItems.map((item) => (
                            <StyledCategory
                                as="li"
                                {...item}
                                imageAspectRatio={categoryAspectRatio}
                                imagesVW={imagesVW}
                                key={weakKey(item)}
                                backgroundColor={backgroundColor}
                                textPosition="on"
                                stretch
                                hoverStyle={hasCategoriesMediaFilter ? 'both' : 'active'}
                            />
                        ))}
                    </StyledM11CategoryList>
                </StyledM11CategoryContent>
            ) : null}
        </>
    );
};
