import React from 'react';
import { ImageMedia } from '~/lib/data-contract';
import { useCookieConsent } from '$shared/hooks';
import { LegacyImage } from '$shared/components';

type M11ImagePropsType = {
    mediaType: 'image' | 'video';
    index: number;
    skeletonShade: 'none' | 'dark' | 'light';
    sizes: string;
    height: 'compact' | 'narrow' | 'normal' | 'tall';
    image?: ImageMedia;
};
export const M11HeroImage = ({
    mediaType,
    image,
    index,
    skeletonShade,
    sizes,
    height,
}: M11ImagePropsType) => {
    const { marketing, statistic } = useCookieConsent();

    const imageHeightBasedRatio = {
        compact: [4, 1],
        narrow: [7, 3],
        normal: [17, 9],
        tall: [22, 15],
    };

    return (
        <>
            {(mediaType === 'image' || (mediaType === 'video' && !(marketing && statistic))) &&
                image?.src && (
                    <LegacyImage
                        {...image}
                        src={image.src}
                        layout="fill"
                        objectFit="cover"
                        sizes={sizes}
                        skeletonShade={skeletonShade}
                        priority={index <= 1}
                        cW={imageHeightBasedRatio[height][0]}
                        cH={imageHeightBasedRatio[height][1]}
                    />
                )}
        </>
    );
};
