import { breakpoints } from '$theme';
import styled from '@emotion/styled';
import { ifProp, switchProp } from 'styled-tools';
import { cover, linearBackground } from '~/shared/utils/styled';
import { Category } from '~/templates/blocks/components/Category';
import { getClampFullRange } from '~/theme/utils/getClampValue';
import { AlignContent } from '../AlignContent';
import { M11HeroProps } from './M11Hero';
import { MediaAlignmentComposition } from '~/lib/data-contract';

type StyledM11Props = Pick<M11HeroProps, 'height' | 'backgroundColor'> & {
    gridTemplateColumns?: string;
    mediaAlignment: MediaAlignmentComposition['mediaHorizontalAlignment'];
    isTwoColumn: boolean;
};
type StyledM11ContentProps = {
    height: M11HeroProps['height'];
    contentAlignment: M11HeroProps['horizontalAlignment'];
    gradient?: boolean;
};

export const StyledMediaWrapper = styled.div({
    ...cover,
});

export const StyledM11CategoryContent = styled.div({
    minWidth: '35%',
    [breakpoints.lg]: {
        minWidth: '30%',
    },
});

export const StyledM11AlignContent = styled(AlignContent)(({ theme }) => ({
    width: '100%',
    zIndex: 2,
    pointerEvents: 'none',
    padding: `${theme.spaces['7']} ${theme.gridConfig.gutter}`,
}));

export const StyledM11 = styled.div<StyledM11Props>(
    ({ theme, backgroundColor, isTwoColumn, mediaAlignment }) => ({
        width: '100%',
        margin: '0 auto',
        backgroundColor,

        [breakpoints.md]: {
            display: 'flex',
            gap: isTwoColumn ? theme.spaces[5] : 'initial',
            padding: theme.spaces[6],
            flexDirection: mediaAlignment === 'right' ? 'row-reverse' : 'row',
        },
    }),
    switchProp('height', {
        compact: {
            minHeight: getClampFullRange(150, 225),
            [breakpoints.sm]: {
                minHeight: `min(40vh, ${getClampFullRange(225, 300)})`,
            },
        },
        narrow: {
            minHeight: getClampFullRange(350, 525),
            [breakpoints.sm]: {
                minHeight: `min(50vh, ${getClampFullRange(350, 525)})`,
            },
        },
        normal: {
            minHeight: getClampFullRange(450, 675),
            [breakpoints.sm]: {
                minHeight: `min(70vh, ${getClampFullRange(450, 675)})`,
            },
        },
        tall: ({ theme: { sizes } }) => ({
            minHeight: getClampFullRange(600, 900),
            [breakpoints.sm]: {
                minHeight: `min(calc(100vh - ${
                    sizes.TotalHeaderHeightDesktop
                }px), ${getClampFullRange(600, 900)})`,
            },
        }),
    })
);

export const StyledM11Content = styled.div<StyledM11ContentProps>(
    {
        display: 'flex',
        flex: 1,
        position: 'relative',
        height: 'auto',
        minHeight: 'inherit',
        maxHeight: 1200,
        width: '100%',
        margin: '0 auto',
        [breakpoints.xs]: {
            maxWidth: '100%',
        },
    },
    ifProp('gradient', ({ theme }) => ({
        '&:after': {
            content: "''",
            ...linearBackground(theme.colors.dark),
            pointerEvents: 'none',
        },
    })),
    switchProp('contentAlignment', {
        left: {
            justifyContent: 'flex-start',
        },
        center: {
            justifyContent: 'center',
        },
        right: {
            justifyContent: 'flex-end',
        },
    })
);

export const StyledM11CategoryList = styled.ul(({ theme }) => ({
    display: 'grid',
    padding: theme.spaces[1],
    gap: theme.spaces[1],
    gridTemplateColumns: '1fr',
    [breakpoints.sm]: {
        gridTemplateColumns: '0.5fr 0.5fr',
    },
    [breakpoints.md]: {
        padding: 'initial',
        gap: theme.spaces[4],
        flexDirection: 'column',
        height: '100%',
        display: 'flex',
    },
}));

export const StyledM11CoverLink = styled.a({
    ...cover,
    zIndex: 1,
});

export const StyledCategory = styled(Category)({
    minHeight: 250,
    [breakpoints.md]: {
        minHeight: 150,
    },
});
